import React, { useEffect, useState } from 'react'
import { createAmberBooking, getAllStaff, getAmberFortShots, getAmberFortSubLocations, getCustomerDetailsAmberFort, Transactions } from '../services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SimpleLogoLoader from '../components/logoloader/SimpleLogoLoader';
import AmberFortFormModal from '../components/AmberFortFormModal';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { v4 as uuidv4 } from "uuid";
import axios from 'axios';
import { tr } from 'date-fns/locale';
import { IoCloseSharp } from "react-icons/io5";
const AmberFortBookingPage = () => {
    
    const bookingsData = useSelector((store) => store.bookings);
    const [subLocationList, setSubLocationList] = useState([]);
    const [selectedSublocation , setSelectedSublocation]=useState();
    const [shots , setShots]=useState([]);
    const [filteredShots , setFilteredShots]=useState([]);
    const [openSublocation , setOpenSublocation]=useState(false);
    const [formData , setFormData]=useState({location_id:bookingsData?.location_id  , location_name:bookingsData?.location_name,op_id:"" ,amount:0, gre_id:"" , bp_id:"",guide_id:"" , current_status:"PENDING" , transaction_id:"null" , path_ids:"" , video_orientation:"landscape" , ticket_id:"" , qr_link:"",transaction_details:{mode:"", booking_type:"single" ,transaction_id:"null" , payment_status:"Completed" , amount:0 , sub_location_id:"" , discount:0 , final_amount:0,no_of_customers:0 , nationality:""}});
    const [defaultFormData , setDefaultFormData]=useState({location_id:bookingsData?.location_id , location_name:bookingsData?.location_name , op_id:"" , gre_id:"" , bp_id:"",guide_id:"", current_status:"PENDING" , transaction_id:"null" , path_ids:"" , video_orientation:"landscape" , ticket_id:"" , qr_link:"",transaction_details:{mode:"", booking_type:"single" ,transaction_id:"null" , payment_status:"Completed" , amount:0 , sub_location_id:"" , discount:0 , final_amount:0,no_of_customers:0 , nationality:""}});
    const [selectedShot , setSelectedShot]=useState(undefined);
    const [loading , setLoading]=useState(false);
    const [amberQrModal ,setAmberQrModal]=useState(false);
    const [resObj , setResObj]=useState(undefined);
    const [amount , setAmount]=useState(0);
    const [openCombo , setOpenCombo]=useState(false);
    const [comboBookings , setComboBooings]=useState(false);
    const [currentTab , setCurrentTab]=useState("booking_create");
    const bulkBookingsList = ["Chandani , Jasmandir , Domb"]
    const paymentModeArr = ["qr" , "cash" , "card" , "foc"];
    const comboSubLocationsObj=[
        {  "sub_location_id": "0052b816-5f6a-4957-b33c-79c65d2d54cf", "sub_location_name": "Chandani", "path_ids": [
        "65afd5fe-aa22-43eb-ba54-bdec9c523f6c-amer"
    ], "shot_id": "751e20cf-aba9-4564-978d-28bb8d6ac640","amount": 499,"shot_mode": "automated","shot_name": "Chandani Shot", "price": {
        "indian": {
            "first": 1333,
            "second": 1333
        },
        "foreigner": {
            "first": 2000,
            "second": 2000
        }
    }},
        { "sub_location_id": "acd4d974-b04c-4a2f-a927-7b86d14c024c", "sub_location_name": "Domb","amount": 499,"shot_id": "2ebefb27-e77e-4378-a5e5-78d6ce9deaf8","shot_mode": "automated",  "shot_name": "Domb Shot",  "path_ids": [
            "65afd5fe-aa22-43eb-ba54-bdec9c523f6c-amer"
        ], "price": {
            "indian": {
                "first": 1333,
                "second": 1333
            },
            "foreigner": {
                "first": 2000,
                "second": 2000
            }
        }},
        { "sub_location_id": "b9aba51a-885b-4599-bab0-92a1436d4375","amount": 499,"sub_location_name": "Jas Mandir","shot_name": "Jas Mandir Shot","shot_id": "c0e9b11b-2ca0-4f89-b04f-bd24fbd2a9a0", "shot_mode": "automated", "path_ids": [
            "65afd5fe-aa22-43eb-ba54-bdec9c523f6c-amer"
        ], "price": {
            "indian": {
                "first": 1333,
                "second": 1333
            },
            "foreigner": {
                "first": 2000,
                "second": 2000
            }
        }}];
    const [comboBookingsArr , setComboBookingsArr]=useState([]);
    const [userFormData , setUserFormData]=useState({customer_name:"" , userType:"" , phone_number:""});
    const [openGre , setOpenGre]=useState(false);
    const [openOp , setOpenOp]=useState(false);
    const [openGuide , setOpenGuide]=useState(false);
    const [openBP , setOpenBP]=useState(false);
    const [allGre , setAllGre]=useState([]);
    const [allOp , setAllOp]=useState([]);
    const [allGuide , setAllGuide]=useState([]);
    const [allBp , setAllBp]=useState([]);
    const [allStaff , setAllStaff]=useState([]);
    const [searchVal ,setSearchVal]=useState('');
    const [discount , setDiscount]=useState(false); 
    //*UserType List
    const userType=["guide" , "operator" , "salesPerson" , "gre"];
    const getSubLocations=async()=>{
        try {
            const response = await getAmberFortSubLocations();
            setSubLocationList(response?.data?.sub_locations);
          } catch (error) {
            console.error("Error fetching feedback questions:", error);
          }     
    }

    const getShots=async()=>{
        try {
            const response = await getAmberFortShots();
            console.log("response :", response);
            setShots(response?.data?.shots);
          } catch (error) {
            console.error("Error fetching Shots:", error);
          }     
    }

    useEffect(()=>{
        getSubLocations();
        getShots();
        getStaff();
    },[]);

    useEffect(()=>{
      setFormData((prev)=>({...prev , location_id:bookingsData?.location_id , location_name:bookingsData?.location_name}));
    },[bookingsData])

    // console.log(subLocationList);
    
    const handleGetShots=async(loc)=>{
        setComboBooings(false);
        setSelectedSublocation(loc);
        setFormData((prev)=>({...prev , sub_location_id:loc?.sub_location_id , sub_location_name:loc?.sub_location_name ,comboBooking:false, transaction_details:{...prev?.transaction_details,booking_type:"single" , sub_location_id:loc?.sub_location_id,amount:0,final_amount:0}}));
        const fil = shots?.filter((val)=>val?.sub_location_id === loc?.sub_location_id);
        setFilteredShots(fil);
        setOpenSublocation(false);
    }

    const handleFormChange=(e , data)=>{
        const name = e.target.name;
        const value = e.target.value;
        if(e.target.name === "shots"){
            if(e.target.checked){
                console.log("shots-data : " , data);
                setSelectedShot(data);
               const shotsObj = {
                   amount:0,
                   shot_id:data?.shot_id,
                   shot_name:data?.shot_name,
                   shot_mode:data?.shot_mode,
                   path_ids:data?.path_ids,
               }
               const txnObj = {
                    amount:0,
                    final_amount:0
               }
               if(formData?.transaction_details?.nationality === "indian"){
                // setAmount(Number(data?.price_per_customer?.indian ?? 0) + Number(data?.amount ?? 0));
                setAmount(Number(data?.price?.indian?.first));
                // if (txnObj) {
                //     txnObj.amount = data?.price?.indian?.first;
                //     txnObj.final_amount = data?.price?.indian?.first;
                // }
                // if(shotsObj){
                //     if (data?.price?.indian?.first) {
                //         shotsObj.amount = data.price.indian.first;
                //     }
                // }
              }else if(formData?.transaction_details?.nationality === "foreigner"){
                // setAmount(Number(data?.price_per_customer?.foreigner ?? 0) + Number(data?.amount ?? 0));
                setAmount(Number(data?.price?.foreigner?.first));
                // if (txnObj) {
                //     txnObj.amount = data?.price?.foreigner?.first;
                //     txnObj.final_amount = data?.price?.foreigner?.first;
                // }
                // if(shotsObj){
                //     if (data?.price?.foreigner?.first) {
                //         shotsObj.amount = data.price.foreigner.first;
                //     }
                // }
               }else{
                // setAmount(data?.price?.indian?.first);
                // if (txnObj) {
                //     txnObj.amount = data?.price?.indian?.first;
                //     txnObj.final_amount = data?.price?.indian?.first;
                // }
                // if(shotsObj){
                //     if (data?.price?.indian?.first) {
                //         shotsObj.amount = data.price.indian.first;
                //     }
                // }
               }
               
                txnObj.final_amount = formData?.transaction_details?.mode === "foc" ? 0 : txnObj?.final_amount;
                if (shotsObj) {
                    shotsObj.amount = formData?.transaction_details?.mode === "foc" ? 0 : shotsObj.amount;
                }
                setFormData((prev)=> ({...prev, ...shotsObj , transaction_details:{...prev?.transaction_details , ...txnObj}}));
            }
        }else if(name === "nationality" && e.target.checked){
            if(e.target.id === "indian"){
                // setAmount(Number(selectedShot?.price_per_customer?.indian ?? 0) + Number(selectedShot?.amount ?? 0));
                if(comboBookings){
                    setAmount(4000);
                }else if(selectedShot){
                    setAmount(Number(selectedShot?.price?.indian?.first));
                    setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , amount:selectedShot?.price?.indian?.first,final_amount:prev?.transaction_details?.mode === "foc" ? 0 : selectedShot?.price?.indian?.first , nationality:e.target.id}}));
                }
              }else if(e.target.id === "foreigner"){
                // setAmount(Number(selectedShot?.price_per_customer?.foreigner ?? 0) + Number(selectedShot?.amount ?? 0));
                if(comboBookings){
                    setAmount(6000);
                }else if(selectedShot){
                    setAmount(Number(selectedShot?.price?.foreigner?.first));
                    setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , amount:selectedShot?.price?.foreigner?.first , final_amount:prev?.transaction_details?.mode === "foc" ? 0 : selectedShot?.price?.foreigner?.first , nationality:e.target.id}}));
                }
               } 
               setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details, nationality:e.target.id}}));
        }else if(name === "paymentMode"){
            if(e.target.id === "foc"){
                setFormData((prev)=>({...prev , transaction_details:{...prev?.transaction_details , mode:e.target.id , final_amount:0}}));
            }else{
                const uuid = uuidv4();
                setFormData((prev)=>({...prev , transaction_id:"" , transaction_details:{...prev?.transaction_details , transaction_id:uuid , mode:e.target.id}}));
            }
        }else if(name === "custom_price"){
            setFormData((prev)=>({...prev , amount:value , transaction_details:{...prev?.transaction_details , amount:value,final_amount:prev?.transaction_details?.mode === "foc" ? 0 : value}}));
        }
        else{
            if(name === "no_of_customers"){
                setFormData((prev)=> ({...prev , transaction_details:{...prev?.transaction_details , no_of_customers:value}}));
            }else{
                setFormData((prev)=>({...prev , [name]:value}))
            }
        }

    };
    
    const handlePhoneNumberChange=(number)=>{
        setFormData((prev)=>({...prev ,phone_number:number }))
    }

    //* Add Booking in Google Sheet - 
    const addBookingGSheet=async(booking , formData , type)=>{
                // setLoading(true);
                const gre = findStaffName(allGre , booking.gre_id);
                const op = findStaffName(allOp , booking.op_id);
                const bp = findStaffName(allBp , booking.bp_id);
                const guide = findStaffName(allGuide , booking.guide_id);
                try {
                const res = await axios.post("https://script.google.com/macros/s/AKfycbwoDIj05T71IqjoxKFk6nqCXHyono-uuSid1TWPSvu1o-GRJiJFJOCGjINK3XyUCT6F/exec",
                    {...booking , ...formData?.transaction_details , gre ,op , bp ,guide}, {
                            redirect: "follow",
                            headers: {
                                "Content-Type": "text/plain;charset=utf-8",
                            },
                    });
                    // toast.success("Your Feedback Submitted Successfully!");
                } catch (error) {
                    console.log("err : err" ,error);
                    toast.error("Something went wrong Gsheet-Booking. Please try again later.");
                }finally{
                    // setLoading(false);
                }
    };

     //* Add Transaction in Google Sheet - 
     const addTransactionGSheet=async(booking , formData)=>{
        // setLoading(true);
        const gre = findStaffName(allGre , booking.gre_id);
        const op = findStaffName(allOp , booking.op_id);
        const bp = findStaffName(allBp , booking.bp_id);
        const guide = findStaffName(allGuide , booking.guide_id);
        try {
        const res = await axios.post("https://script.google.com/macros/s/AKfycbwoDIj05T71IqjoxKFk6nqCXHyono-uuSid1TWPSvu1o-GRJiJFJOCGjINK3XyUCT6F/exec",
            {...booking , ...formData?.transaction_details , isDiscount:discount , gre ,op , bp ,guide , sheetName:"transactions"}, {
                    redirect: "follow",
                    headers: {
                        "Content-Type": "text/plain;charset=utf-8",
                    },
            });
            // toast.success("Your Feedback Submitted Successfully!");
        } catch (error) {
            console.log("err : err" ,error);
            toast.error("Something went wrong in Gsheet. Please try again later.");
        }finally{
            // setLoading(false);
        }
    };
   
    //* CreateTransaction api:
    const CreateTransaction=async(booking ,formData)=>{
        try {
            let customers = [];
            customers.push({ ...booking });
            const res = await Transactions({customers:customers , ...formData?.transaction_details });
            addTransactionGSheet(booking , formData);
            console.log("res-transaction ----------- >>> > > > >: " , res);
            toast.success("Transaction Created Successuflly");
        } catch (error) {
            toast.error("Transaction not create");
        }
    }
    const createBookingApi=async(c_id)=>{
        try{
            const res = await createAmberBooking(c_id ,formData);
            if(res?.data?.is_success){
                console.log("create-booking : " , res.data);
                CreateTransaction(res?.data?.booking ,formData);
                addBookingGSheet(res?.data?.booking , formData);
                setResObj(res?.data?.booking)
                setAmberQrModal(true);
                toast.success(res?.data?.message);
                setFormData(defaultFormData);
                setFilteredShots([]);
                setLoading(false);
            }else{
                toast.info(res?.data?.message);
                setLoading(false);
            }
            console.log("create-bookings : ", res);
        }catch(err){
            toast.error("Something went wrong kindly try after some time");
            setLoading(false);
        }
    }

    const comboCreateBookingApi=async(c_id , updateData , idx)=>{
        console.log("updateData :" , updateData);
            try{
                const res = await createAmberBooking(c_id ,updateData);
                console.log("updatedData-combo:" , updateData);
                if(res?.data?.is_success){
                    toast.success(`${idx+1}-Booking--${res?.data?.message}`);
                    if(idx === 0){
                        CreateTransaction(res?.data?.booking ,updateData);
                    }
                    addBookingGSheet(res?.data?.booking , updateData);
                    if(idx === 2){
                        setResObj(res?.data?.booking)
                        setAmberQrModal(true);
                        setFormData(defaultFormData);
                        setFilteredShots([]);
                        setLoading(false);
                        setComboBooings(false);
                        setComboBookingsArr([]);
                    }
                   return res?.data?.booking?.booking_id;
                }else{
                    toast.info(res?.data?.message);
                    if(idx === 2){
                        setLoading(false);
                    }
                }
                console.log("create-bookings : ", res);
            }catch(err){
                toast.error("Something went wrong kindly try after some time");
                setLoading(false);
            };
    }

 
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const combobookingsCreateBookings = async (c_id) => {
        let customAmount = 0;
        if (formData?.transaction_details?.amount && formData?.transaction_details?.final_amount) {
            customAmount = Math.round(formData?.transaction_details?.final_amount / 3);
        }
        let comboBookingsIdArr=[];
        // Use for...of instead of forEach to handle async operations sequentially
        for (const [idx, val] of comboSubLocationsObj.entries()) {
            let updateBookingObj = {};
            console.log("before insert ---------->? ", idx , comboBookingsIdArr);
            if(idx === 2){
                updateBookingObj = {bookingArr:comboBookingsIdArr , subLocationArr:["Chandani" ,"Domb" , "Jasmandir"]}
            };
            if (customAmount > 0) {
                updateBookingObj = {
                    ...updateBookingObj,
                    ...formData,
                    sub_location_id: val?.sub_location_id,
                    sub_location_name: val?.sub_location_name,
                    shot_id: val?.shot_id,
                    shot_name: val?.shot_name,
                    shot_mode: val.shot_mode,
                    path_ids: val?.path_ids,
                    transaction_details: {
                        ...formData?.transaction_details,
                        sub_location_id: val?.sub_location_id,
                    }
                };
            } else if (formData?.transaction_details?.mode === 'foc') {
                if (formData?.transaction_details?.nationality === "indian") {
                    updateBookingObj = {
                        ...updateBookingObj,
                        ...formData,
                        sub_location_id: val?.sub_location_id,
                        sub_location_name: val?.sub_location_name,
                        amount: 0,
                        shot_id: val?.shot_id,
                        shot_name: val?.shot_name,
                        shot_mode: val.shot_mode,
                        path_ids: val?.path_ids,
                        transaction_details: {
                            ...formData?.transaction_details,
                            sub_location_id: val?.sub_location_id,
                            amount: val?.price?.indian?.first,
                            final_amount: 0
                        }
                    };
                } else if (formData?.transaction_details?.nationality === "foreigner") {
                    // ... similar structure for foreigner
                }
            } else {
                // ... rest of your conditions
            }
    
            console.log("updateBookingObj -- >", updateBookingObj);
            
            // Wait for each API call to complete before moving to the next iteration
            let b_id = await comboCreateBookingApi(c_id, updateBookingObj, idx);
            comboBookingsIdArr.push(b_id);
         
        }
    };

    const getCustomerDetailsApiCall=async()=>{
        if(!formData?.customer_name || !formData?.phone_number){
            toast.info("Kindly Must Be Filled Name and Phone number");
            return ;
        }
        if(isValidPhoneNumber(formData?.phone_number) === false){
            toast.info("Kindly Must Be Filled Correct Phone number");
            return;
        }
        if(Number(formData?.transaction_details?.final_amount) < 0){
            toast.info("Please enter a valid amount.");
            return ;
        }
        if(Number(formData?.transaction_details?.final_amount) === 0 && formData?.transaction_details?.mode !== "foc"){
            toast.info("You have only chosen ₹0 with FOC");
            return ;
        }
        if(!formData?.gre_id || !formData?.guide_id || !formData?.op_id || !formData?.bp_id ){
            toast.info("Kindly Must Be Filled Staff details");
            return ;
        };
        setLoading(true);
        
      try{
            const res = await getCustomerDetailsAmberFort({customer_name :formData?.customer_name ,phone_number:formData?.phone_number});
            if(res?.data?.is_success){
                if(formData?.transaction_details?.nationality && formData?.transaction_details?.mode){   
                    if(comboBookings){
                        combobookingsCreateBookings(res?.data?.customer?.customer_id);
                    }else{
                        if(formData?.shot_id && formData?.shot_name && formData?.shot_mode && formData?.path_ids){
                            await createBookingApi(res?.data?.customer?.customer_id);
                        }else{
                            setLoading(false);
                            toast.info("Kindly Must Be Select Shot");
                        }
                        
                    }
                }else{
                    setLoading(false);
                    toast.info("Kindly Must Be Filled Nationality and Payment Mode");
                } 
            }else{
                toast.info(res?.data?.message);
                setLoading(false);
            }
  
        }catch(err){
            setLoading(false);
        }finally{
            setLoading(false);
        }
    }

    const handleSubmit= async (e)=>{
        e.preventDefault();
       getCustomerDetailsApiCall();
    };

    const handleSublocation=(e)=>{
        setOpenSublocation(e.target.checked);
        // setAmount(0);
    }
   
    const handleBulkBooking=()=>{
        setComboBooings(true);
        setOpenCombo(false);
        setFormData((prev)=>({...prev , sub_location_id:"" , sub_location_name:"" , comboBooking:true , transaction_details:{...prev?.transaction_details,booking_type:"combo" , sub_location_id:""}}));
        if(formData?.transaction_details?.nationality === "indian"){
         setAmount(4000);
        }else if(formData?.transaction_details?.nationality === "foreigner"){
           setAmount(6000);
        }else{
            toast.info("Kindly Select Nationality");
        };
        
        setFilteredShots([]);
        setSelectedSublocation();
    }
   
     //* userForm handle
     const handleUserFormData=(e)=>{
        const key = e.target.name;
        const val = e.target.value;
        setUserFormData((prev)=>({...prev , [key]:val}));
     }
   
     const callCreateUser=async(userFormData)=>{
        try {
            return await axios.post(`https://umlks472ud.execute-api.us-east-1.amazonaws.com/dev/staff`, userFormData);
          } catch (err) {
              console.log(err);
          }
     }
     //* handleUser Formdata
     const handleUserFormSubmit=async(e)=>{
       e.preventDefault();
       setLoading(true);
       try{
                const res = await callCreateUser(userFormData);
                // if(res?.data?.is_success){
                    console.log("create-booking : " , res.data);
                    toast.info(res?.data?.message);
                    setUserFormData({customer_name:"" , userType:"" , phone_number:""});
                    setCurrentTab("booking_create");
                    getStaff();
                // }else{
                //     toast.info(res?.data?.message);
                //     setLoading(false);
                // }
                console.log("create-bookings : ", res);
            }catch(err){
                toast.error("Something went wrong kindly try after some time");
                setLoading(false);
        }finally{
            setLoading(false);
        }
        console.log("user-formdata" , userFormData);
     };
   

     // * Get All Staff call 
     const getStaff =async()=>{
        setLoading(true);
        try {
             const res = await getAllStaff();
             console.log("res ; " , res);
             if(res?.data?.is_success){
               setAllStaff(res?.data?.allStaff);
               const gre = res?.data?.allStaff?.filter((val)=> val?.user_type  ===  "gre");
               const op = res?.data?.allStaff?.filter((val)=> val?.user_type  ===  "operator");
               const sp = res?.data?.allStaff?.filter((val)=> val?.user_type  ===  "salesPerson" ||  val?.name  ===  "Self");
               const guide = res?.data?.allStaff?.filter((val)=> val?.user_type  ===  "guide");
               setAllGre(gre);
               setAllBp(sp);
               setAllOp(op);
               setAllGuide(guide);
             }else{

             }
        } catch (error) {
            console.log("staff -errror : " , error);
        }finally{
            setLoading(false);
        }
     };

     const findStaffName=(arr , id , type)=>{
        const result = arr.find(item => item.user_id === id);
        // console.log("result-name : " , result?.name);
        if(type === "guide"){
            return `${result?.name} - ${result?.phone_number}`
        }
        return result?.name;
     } 
     // * Handle Filter Staff 
     const handleFilterStaff=(e , type)=>{
        const searchVal = e.target.value;
        setSearchVal({type,val:searchVal});
       if(type === "guide"){ 
        const filteredGuides = allStaff?.filter((val) => val?.user_type === "guide" && val?.name.toLowerCase().includes(searchVal.toLowerCase()));
        setAllGuide(filteredGuides); // Update the state with filtered guides
       }else if(type === "gre"){
        const filteredGre = allStaff?.filter((val) => val?.user_type === "gre" && val?.name.toLowerCase().includes(searchVal.toLowerCase()));
        setAllGre(filteredGre);
       }else if(type === "op"){
        const filteredOp = allStaff?.filter((val) => val?.user_type === "operator" && val?.name.toLowerCase().includes(searchVal.toLowerCase()));
        setAllOp(filteredOp);
       }else{
        const filteredBp = allStaff?.filter((val) => (val?.user_type  ===  "salesPerson" ||  val?.name  ===  "Self") && val?.name.toLowerCase().includes(searchVal.toLowerCase()));
        setAllBp(filteredBp);
       }
     }

    console.log("formData :-->", formData);
    // console.log("amount : " , amount);
    // console.log("currentTab", currentTab);
    // console.log("shots : " , shots);
    // console.log("comboBookingsArr : " , comboBookingsArr);
    
  return (
    <>
    
    <div className='relative flex flex-col justify-center items-center my-5 h-full'>

    {loading && <div className='z-20 w-screen h-full flex items-center justify-center bg-[#ffffffcc] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <SimpleLogoLoader logoWidth="100px"/> </div> }
         
        <div className="border-b border-gray-200 dark:border-neutral-700 w-[90%] flex justify-center items-center">
            <nav className="flex gap-x-1" aria-label="Tabs" role="tablist" aria-orientation="horizontal">
                <button type="button" onClick={()=>setCurrentTab("booking_create")} className={`${currentTab === "booking_create" ? "font-semibold border-blue-600 active:text-blue-600" : ""} py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50  dark:text-neutral-400 dark:hover:text-blue-500 `}  role="tab">
                <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                </svg>
                Booking Create
                </button>
                <button type="button" onClick={()=>setCurrentTab("user_create")} className={`${currentTab === "user_create" ? "font-semibold border-blue-600 active:text-blue-600" : ""} py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50  dark:text-neutral-400 dark:hover:text-blue-500 `} role="tab">
                <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10"></circle>
                    <circle cx="12" cy="10" r="3"></circle>
                    <path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662"></path>
                </svg>
                User Create
                </button>
                {/* <button type="button" className="hs-tab-active:font-semibold hs-tab-active:border-blue-600 hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-x-2 border-b-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:text-blue-500" id="tabs-with-icons-item-3" aria-selected="false" data-hs-tab="#tabs-with-icons-3" aria-controls="tabs-with-icons-3" role="tab">
                <svg className="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                </svg>
                Tab 3
                </button> */}
            </nav>
        </div>

    
     {currentTab === "booking_create" ?  <div className='flex flex-col justify-center items-center'>
        <h1 className='my-2 text-2xl bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text'>Booking Creating Form</h1>
        <form action="" onSubmit={handleSubmit} className='flex flex-wrap w-[60%] md:w-[70%] mmd:w-[90%] sm:w-full shadow-2xl rounded-xl p-5'>
             
            <div className='flex flex-wrap justify-center gap-5 w-full items-center'>
                 <div className='sm:w-full'>
                    <p className="font-medium mb-1 text-gray-500 ">Select GRE</p>
                    <div className='relative'>
                      <input type='search'   className={`rounded-lg border text-sm text-gray-700 ring-blue-400 w-full ${formData?.gre_id ? "bg-blue-100" : ""}`} onChange={(e)=>handleFilterStaff(e,"gre")} onClick={()=>setOpenGre((prev)=>!prev)} value={formData?.gre_id ? findStaffName(allGre , formData?.gre_id , "gre"): searchVal?.type === "gre" ? searchVal?.val : ''}/>
                      {(searchVal?.type === "gre" || formData?.gre_id) && <button 
                            type="button" 
                            className="absolute right-2 top-2 text-red-400" 
                            onClick={() => {
                                setFormData((prev) => ({ ...prev, gre_id: "" })); // Clear guide_id in formData
                                setOpenGre(false); // Close the dropdown
                                setSearchVal('');
                                setAllGre(allStaff?.filter((val) => val?.user_type === "gre"));
                            }}
                        >
                           <IoCloseSharp className='text-red-500 text-2xl bg-white'/>
                       </button> }
                    </div>
                    <ul className={`${openGre ? "max-h-[200px] py-3 " : "max-h-0 hidden"} select-none flex-col overflow-auto rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                        {allGre?.map((data,idx)=>(
                            <li onClick={()=>{
                                setFormData((prev)=>({...prev , gre_id:data?.user_id}));
                                setOpenGre(false);
                            }} key={idx} className={`peer-checked:ring ${formData?.gre_id === data?.user_id ? "bg-blue-500 text-white" : ''} cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{data?.name}</li>
                        ))}
                    </ul>
                </div>

                <div className='sm:w-full'>
                    <p className="font-medium mb-1 text-gray-500 ">Select Operator</p>
                    <div className='relative'>
                      <input type='search' className={`rounded-lg border text-sm text-gray-700 ring-blue-400 w-full ${formData?.op_id ? "bg-blue-100" : ""}`} onChange={(e)=>handleFilterStaff(e,"op")} onClick={()=>setOpenOp((prev)=>!prev)} value={formData?.op_id ? findStaffName(allOp , formData?.op_id , "op"): searchVal?.type === "op" ? searchVal?.val : ''}/>
                      {(searchVal?.type === "op" || formData?.op_id) && <button 
                            type="button" 
                            className="absolute right-2 top-2 text-red-400" 
                            onClick={() => {
                                setFormData((prev) => ({ ...prev, op_id: "" })); // Clear guide_id in formData
                                setOpenOp(false); // Close the dropdown
                                setSearchVal('');
                                setAllOp(allStaff?.filter((val) => val?.user_type === "operator"));
                            }}
                        >
                           <IoCloseSharp className='text-red-500 text-2xl bg-white'/>
                       </button> }
                    </div>
                    <ul className={`${openOp ? "max-h-[200px] py-3 " : "max-h-0 hidden"}select-none flex-col overflow-auto rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                        {allOp?.map((data,idx)=>(
                            <li onClick={()=>{
                                setFormData((prev)=>({...prev , op_id:data?.user_id}));
                                setOpenOp(false);
                            }} key={idx} className={`peer-checked:ring ${formData?.op_id === data?.user_id ? "bg-blue-500 text-white" : ''} cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{data?.name}</li>
                        ))}
                    </ul>
                </div>
            </div> 
           
           <div className='flex justify-center items-center w-full'>
            <div className="relative flex w-[60%] sm:w-[100%] items-center rounded bg-gray-50 py-2 px-4 pl-14 font-medium text-gray-700 mt-5 justify-center ">
                    <input className="peer hidden " type="radio" name="guideMode" id={"Self"}  onChange={(e)=>{
                        if(e.target.checked){
                            setFormData((prev)=>({...prev , bp_id:"bdb43b49-9856-4d2e-8eb3-f70c7a50a63c" , guide_id:"bdb43b49-9856-4d2e-8eb3-f70c7a50a63c"}));
                        }else{
                            setFormData((prev)=>({...prev , bp_id:"" , guide_id:""})); 
                        };
                    }} checked={formData?.guide_id === "bdb43b49-9856-4d2e-8eb3-f70c7a50a63c" && formData?.bp_id === "bdb43b49-9856-4d2e-8eb3-f70c7a50a63c"}/>
                    <label className="absolute left-0 top-0 h-full w-full cursor-pointer rounded border peer-checked:border-blue-600 peer-checked:bg-blue-100" htmlFor={"Self"}> </label>
                    <div className="absolute left-4 h-3 w-3 rounded border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2 peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2"></div>
                    <span className="pointer-events-none z-10">Self</span>
            </div>
           </div>


            <div className='flex flex-wrap justify-center items-center gap-5 w-full mt-4'>
                <div className='sm:w-full'>
                    <p className="font-medium mb-1 text-gray-500 ">Select SalesPerson</p>
                    <div className='relative'>
                      <input type='search'  className={`rounded-lg border text-sm text-gray-700 ring-blue-400 w-full ${formData?.bp_id ? "bg-blue-100" : ""}`} onChange={(e)=>handleFilterStaff(e,"bp")} onClick={()=>setOpenBP((prev)=>!prev)} value={formData?.bp_id ? findStaffName(allBp , formData?.bp_id , "bp"): searchVal?.type === "bp" ? searchVal?.val : ''}/>
                      {(searchVal?.type === "bp" || formData?.bp_id) && <button 
                            type="button" 
                            className="absolute right-2 top-2 text-red-400" 
                            onClick={() => {
                                setFormData((prev) => ({ ...prev, bp_id: "" })); // Clear guide_id in formData
                                setOpenBP(false); // Close the dropdown
                                setSearchVal('');
                                setAllBp(allStaff?.filter((val) => (val?.user_type  ===  "salesPerson" ||  val?.name  ===  "Self")));
                            }}
                        >
                           <IoCloseSharp className='text-red-500 text-2xl bg-white'/>
                       </button> }
                    </div>
                    <ul className={`${openBP ? "max-h-[200px] py-3 " : "max-h-0 hidden"} select-none flex-col overflow-auto rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                        {allBp?.map((data,idx)=>(
                            <li onClick={()=>{
                                setFormData((prev)=>({...prev , bp_id:data?.user_id}));
                                setOpenBP(false);
                            }} key={idx} className={`peer-checked:ring ${formData?.bp_id === data?.user_id ? "bg-blue-500 text-white" : ''} cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{data?.name}</li>
                        ))}
                    </ul>
                </div>

                <div className='relative sm:w-full'>
                    <p className="font-medium mb-1 text-gray-500 ">Select Guide</p>
                    <div className='relative'>
                      <input type='search'  className={`rounded-lg border text-sm text-gray-700 ring-blue-400 w-full ${formData?.guide_id ? "bg-blue-100" : ""}`} onChange={(e)=>handleFilterStaff(e,"guide")} onClick={()=>setOpenGuide((prev)=>!prev)} value={formData?.guide_id ? findStaffName(allGuide , formData?.guide_id , "guide"): searchVal?.type === "guide" ? searchVal?.val:''}/>
                      {(searchVal?.type === "guide" || formData?.guide_id) && <button 
                            type="button" 
                            className="absolute right-2 top-2 text-red-400" 
                            onClick={() => {
                                setFormData((prev) => ({ ...prev, guide_id: "" })); // Clear guide_id in formData
                                setOpenGuide(false); // Close the dropdown
                                setSearchVal('');
                                setAllGuide(allStaff?.filter((val) => val?.user_type === "guide" ));
                            }}
                        >
                           <IoCloseSharp className='text-red-500 text-2xl bg-white'/>
                       </button> }
                    </div>
                    <ul className={`${openGuide ? "max-h-[200px] py-3 " : "max-h-0 hidden"} overflow-auto select-none flex-col rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                        {allGuide?.map((data,idx)=>(
                            <li onClick={()=>{
                                setFormData((prev)=>({...prev , guide_id:data?.user_id}));
                                setOpenGuide(false);
                            }} key={idx} className={`peer-checked:ring ${formData?.guide_id === data?.user_id ? "bg-blue-500 text-white" : ''} cursor-pointer px-2 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{data?.name} - {data?.phone_number}</li>
                        ))}
                    </ul>
                </div>

            </div>


            <div className="m-5 w-full">
                <label htmlFor="price" className="mb-2 block text-sm font-medium">Name</label>
                <div className="relative">
                    <input type="text" id="customer_name" name="customer_name" onChange={handleFormChange} value={formData?.customer_name ? formData?.customer_name : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Name" required/>
                </div>
            </div>

           
            <div className="m-5 w-full">
                <label htmlFor="phone" className="mb-2 block text-sm font-medium">Phone number</label>
                <div className="relative">
                    {/* <input type="text" id="phone_number" name="phone_number" onChange={handleFormChange} value={formData?.phone_number ? formData?.phone_number : ""}  className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-24 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="+91 00000-00000" required />
                    <div className="absolute inset-y-0 left-0 flex items-center px-3 text-gray-500">
                    <label htmlFor="country-code" className="sr-only">Country</label>
                    <select id="country-code" name="country-code" className="block w-full rounded-md border-transparent focus:border-blue-600 focus:ring-blue-600">
                        <option>US</option>
                        <option>CA</option>
                        <option>EU</option>
                    </select> */}
                    {/* </div> */}
                    <PhoneInput
                        className="rounded-full"
                        international
                        placeholder="+91 00000-00000"
                        defaultCountry="IN"
                        value={formData?.phone_number ? formData?.phone_number : ""}
                        onChange={handlePhoneNumberChange} 
                        
                    />
                        
                </div>
            </div>

            <div className="m-5 w-full">
                <label htmlFor="price" className="mb-2 block text-sm font-medium">Group Size</label>
                <div className="relative">
                    <input type="text" id="no_of_customers" name="no_of_customers" onChange={handleFormChange} value={formData?.transaction_details?.no_of_customers ? formData?.transaction_details?.no_of_customers : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Group Size" required/>
                </div>
            </div>
          
            <div className='m-5 w-full '>
            <p className="font-medium mb-1 text-gray-500 ">Nationality ?</p>
            <div className="flex sm:flex-col gap-6">
                {[{name:"Indian" , val:"indian"} , {name:"Foreigner" , val:"foreigner"}]?.map((data,idx)=>(
                        <div key={idx} className="w-full relative flex  items-center justify-center rounded-xl bg-gray-50 py-3 font-medium text-gray-700">
                        <input className="hidden" type="radio" name="nationality" id={data?.val} onChange={handleFormChange}  checked={formData?.transaction_details?.nationality === data?.val}/>
                        <label className={`${formData?.transaction_details?.nationality === data?.val ? "border-blue-400 peer-checked:bg-blue-200" :"" } absolute top-0 h-full w-full cursor-pointer rounded-xl border`} htmlFor={data?.val}> </label>
                        <div className={`${formData?.transaction_details?.nationality === data?.val ? "border-transparent bg-blue-400 ring-2     " :"" }absolute left-4 h-5 w-5 rounded-full border-2 border-gray-300 bg-gray-200 ring-blue-400 ring-offset-2`}></div>
                        <span className="pointer-events-none z-10">{data?.name}</span>
                    </div>
                ))}
            </div>
            </div>
            
            
            
            <div className="w-full flex flex-wrap gap-5 justify-between m-5">
                <div>
                    <p className="font-medium mb-1 text-gray-500 ">Select Sublocation</p>
                    <input className="peer hidden" type="checkbox" name="select-1" id="select-1" value={openSublocation}  onChange={handleSublocation} />
                    <label htmlFor="select-1" className="flex w-full cursor-pointer select-none rounded-lg border py-3 px-4 text-center pt-2 text-sm text-gray-700 ring-blue-400 peer-checked:ring">{formData?.sub_location_name ? formData?.sub_location_name : "Select Sublocation" }</label>
            
                    <ul className={`${openSublocation ? "max-h-fit py-3 " : "max-h-0 hidden"}select-none flex-col overflow-hidden rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                        {subLocationList?.map((loc,idx)=>(
                            <li onClick={()=>{
                                handleGetShots(loc);
                                setAmount(0);
                            }} key={idx} className={`peer-checked:ring ${formData?.sub_location_name === loc?.sub_location_name ? "bg-blue-500 text-white" : ''} cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{loc?.sub_location_name}</li>
                        ))}
                    </ul>
                </div>
                <div>
                    <p className="font-medium mb-1 text-gray-500 ">Select BulkBookings</p>
                    <input className="peer hidden" type="checkbox" name="select-2" id="select-2" value={openCombo}  onChange={(e)=>setOpenCombo(e.target.checked)} />
                    <label htmlFor="select-2" className="flex w-full cursor-pointer select-none rounded-lg border py-3 px-4 text-center pt-2 text-sm text-gray-700 ring-blue-400 peer-checked:ring">{comboBookings ? "Chandani , Jasmandir , Domb" : "Select Combo" }</label>
            
                    <ul className={`${openCombo ? "max-h-56 py-3 " : "max-h-0 hidden"}select-none flex-col overflow-hidden rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                        {bulkBookingsList?.map((loc,idx)=>(
                            <li onClick={handleBulkBooking} key={idx} className={`peer-checked:ring ${comboBookings ? "bg-blue-500 text-white" : ''} cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{loc}</li>
                        ))}
                    </ul>
                </div>
            </div>
            
            <div className="m-5 w-full">
                <label htmlFor="price" className="mb-2 block text-sm font-medium">Custom Price &#8377;</label>
                <div className="relative">
                    <input type="text" id="custom_price" name="custom_price" onChange={handleFormChange} value={formData?.transaction_details?.final_amount !== "" ? formData?.transaction_details?.final_amount : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Custom Price" required />
                </div>

                <div className="relative flex w-60 items-center rounded bg-gray-50 py-2 px-4 pl-14 font-medium text-gray-700 mt-5">
                    <input className="peer hidden" type="radio" name="discount" id="discount"  onClick={()=>{setDiscount((prev)=>!prev)}}  checked={discount}/>
                    <label className="absolute left-0 top-0 h-full w-full cursor-pointer rounded border peer-checked:border-blue-600 peer-checked:bg-blue-100" htmlFor="discount"> </label>
                    <div className="absolute left-4 h-3 w-3 rounded border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2 peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2"></div>
                    <span className="pointer-events-none z-10">Discount</span>
                </div>
           
            </div>
            {/* // {comboBookings && <div className="w-fit space-y-2 m-5"> <h1>{amount}</h1> </div>} */}
           {filteredShots?.length > 0 &&  <div className="w-fit space-y-2 m-5">
                <h2 className="text- font-medium text-gray-700">Select your Shots</h2>
                {/* <h1>{amount}</h1> */}
             <div className='flex gap-4 flex-wrap'>
                {filteredShots?.map((shots , idx)=>(
                    <div key={idx} className="relative flex min-w-40 items-center rounded-lg bg-gray-50 py-2 px-4 pl-14 font-medium text-gray-700">
                    <input className="peer hidden" type="radio" name="shots" id={shots?.shot_name} onChange={(e)=>handleFormChange(e,shots)} checked={formData?.shot_id === shots?.shot_id} />
                    <label className="absolute left-0 top-0 h-full w-full cursor-pointer rounded border peer-checked:border-blue-600 peer-checked:bg-blue-100" htmlFor={shots?.shot_name}> </label>
                    <div className="absolute left-4 h-3 w-3 rounded border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2 peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2"></div>
                    <span className="pointer-events-none z-10">{shots?.shot_name}</span>
                    </div>
                ))}
                
            </div>
            </div>}

            <div className="w-fit space-y-2 m-5">
                <h2 className="text- font-medium text-gray-700">Select your Payment Mode</h2>
             <div className='flex gap-4 flex-wrap'>
                {paymentModeArr?.map((mode,idx)=>(
                    <div key={idx} className="relative flex w-60 items-center rounded bg-gray-50 py-2 px-4 pl-14 font-medium text-gray-700">
                    <input className="peer hidden" type="radio" name="paymentMode" id={mode}  onChange={(e)=>handleFormChange(e)} checked={formData?.transaction_details?.mode === mode}/>
                    <label className="absolute left-0 top-0 h-full w-full cursor-pointer rounded border peer-checked:border-blue-600 peer-checked:bg-blue-100" htmlFor={mode}> </label>
                    <div className="absolute left-4 h-3 w-3 rounded border-2 border-gray-300 bg-gray-200 ring-blue-600 ring-offset-2 peer-checked:border-transparent peer-checked:bg-blue-600 peer-checked:ring-2"></div>
                    <span className="pointer-events-none z-10">{mode?.toUpperCase()}</span>
                </div>
                ))}
                
            </div>
            </div>
            
            <div className='w-full'>
                <button type='submit' className="mt-4 rounded-full bg-blue-800 px-10 py-2 font-semibold text-white">Submit- ₹{formData?.transaction_details?.final_amount}</button>
            </div>
            
        </form>
      </div>
         :
      <div className='flex flex-col justify-center items-center'>
        <h1 className='my-2 text-2xl bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text'>User Creating Form </h1>
        <form action="" onSubmit={handleUserFormSubmit} className='flex flex-wrap w-[60%] md:w-[70%] mmd:w-[90%] sm:w-full shadow-2xl rounded-xl p-5'>

            <div className="m-5 w-full">
                <label htmlFor="price" className="mb-2 block text-sm font-medium">Name</label>
                <div className="relative">
                    <input type="text" id="customer_name" name="customer_name" onChange={handleUserFormData} value={userFormData?.customer_name ? userFormData?.customer_name : ""} className="block w-full rounded-md border border-gray-200 py-3 px-4 pl-9 pr-16 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Name" required/>
                </div>
            </div>

           
            <div className="m-5 w-full">
                <label htmlFor="phone" className="mb-2 block text-sm font-medium">Phone number</label>
                <div className="relative">
                    <PhoneInput
                        className="rounded-full"
                        international
                        placeholder="+91 00000-00000"
                        defaultCountry="IN"
                        value={setUserFormData?.phone_number ? setUserFormData?.phone_number : ""}
                        onChange={(num)=> setUserFormData((prev)=>({...prev ,phone_number:num }))}/>
                        
                </div>
            </div>

            
            <div className="w-full flex flex-wrap gap-5 justify-between m-5">
                <div>
                    <p className="font-medium mb-1 text-gray-500 ">Select Usertype</p>
                    <input className="peer hidden" type="checkbox" name="select-user" id="select-user" value={openSublocation}  onChange={handleSublocation} />
                    <label htmlFor="select-user" className="flex w-full cursor-pointer select-none rounded-lg border py-3 px-4 text-center pt-2 text-sm text-gray-700 ring-blue-400 peer-checked:ring">{userFormData?.userType ? userFormData?.userType : "Select UserType" }</label>
            
                    <ul className={`${openSublocation ? "max-h-fit py-3 " : "max-h-0 hidden"}select-none flex-col overflow-hidden rounded-b-lg shadow-xl transition-all duration-300  bg-white`}>
                        {userType?.map((type,idx)=>(
                            <li onClick={()=>{
                                setUserFormData((prev)=>({...prev , userType:type}));
                                setOpenSublocation(false);
                            }} key={idx} className={`peer-checked:ring ${setUserFormData?.userType === type ? "bg-blue-500 text-white" : ''} cursor-pointer px-3 py-2 text-sm text-gray-500 hover:bg-blue-500 hover:text-white`}>{type}</li>
                        ))}
                    </ul>
                </div>
             
            </div>
           
            <div className='w-full'>
                <button type='submit' className="mt-4 rounded-full bg-blue-800 px-10 py-2 font-semibold text-white">Submit</button>
            </div>
            
        </form>
      </div> }
     
    </div>
    
         <AmberFortFormModal modalOpen={amberQrModal} onCloseModal={()=>setAmberQrModal(false)} resObj={resObj}/>
    </>
  )
}

export default AmberFortBookingPage